import React, { useContext, useEffect } from 'react';
import './Header.scss';
import HeaderLogo from 'assets/images/alltech-connect-logo2.svg';
import LogoutIcon from 'assets/images/ic-logout.svg';
import { useLocation } from 'react-router';
import {
  HeaderConfig,
  notificationsRoutes,
  notificationTexts,
  generateNotificationText,
} from './config';
import { HeaderLinkInterface } from './types';
import { Link } from 'react-router-dom';
import { AuthContext } from 'providers/AuthProvider';
import { IAppState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import {
  getNotifications,
  closeNotification,
} from 'store/notifications/actions';
import { Notification } from './Notfication';
import { NotificationsTypes } from '../../../store/notifications/types';
import { useTranslation } from 'react-i18next';
import { authActions } from '../../../store/auth/actions';
import Api from 'api';

const Header = () => {
  const { pathname } = useLocation();
  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const profile = useSelector((state: IAppState) => state.auth?.profile);
  // const language = useSelector((state: IAppState) => state.auth?.language);
  const language = localStorage.getItem('lang') || 'en';
  // const [language, setLanguage] = React.useState<string>('en')
  const userRole = useSelector(
    (state: IAppState) => state.auth.profile?.UserRoleName,
  );
  const logoutHandler = () => {
    logout();
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(language);
    Api(true)
      .get('/api/User/GetDefaultLanguage')
      .then(function (payload) {
        //setLanguage(payload.data)
        localStorage.setItem('lang', payload.data);
        if (payload.data === 'es') i18n.changeLanguage('es');
        if (payload.data === 'ch') i18n.changeLanguage('ch');
        if (payload.data === 'pt') i18n.changeLanguage('po');
      });
    //.then(resp => console.log(resp.data))
    // fetch('https://api.ipregistry.co/?key=tryout')
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (payload) {
    //     localStorage.setItem('country', payload.location?.country?.name);
    //     if(payload.location?.country?.name === 'Spain') i18n.changeLanguage('es')
    //     if(payload.location?.country?.name === 'China') i18n.changeLanguage('ch')
    //     if(payload.location?.country?.name === 'Portugal') i18n.changeLanguage('po')
    //   });
  }, []);

  const changeLanguage = language => {
    localStorage.setItem('lang', language.target.value);
    //setLanguage(language.target.value)
    i18n.changeLanguage(language.target.value);
    Api(true).get(
      `api/User/SetDefaultLanguage?languageCode=${language.target.value}`,
    );
    // dispatch(authActions.authLanguageAction(language.target.value));
  };

  const notificationsState = useSelector(
    (state: IAppState) => state.notifications,
  );
  const notifications = notificationsState.notifications.filter(n => {
    if (!notificationsState[n?.Type?.Name].closed) {
      return n;
    }
  });

  let notificationsSet: Set<string> = new Set();
  notifications.forEach(n => {
    if (n?.Type?.Name !== NotificationsTypes.general)
      notificationsSet.add(n?.Type?.Name);
  });
  const notificationsObjects = Array.from(notificationsSet).map(type => ({
    message: generateNotificationText(
      profile?.FullName,
      notificationTexts[type],
    ),
    to: notificationsRoutes[type],
    type: type,
  }));

  const data: Array<HeaderLinkInterface> = HeaderConfig[userRole] || [];

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);
  // const showBreadcrumbs = FORBIDDEN_APP_BREAD_CRUMBS.includes(location.pathname) ? false : true;

  return (
    <header className="header">
      <div className="header-top">
        <div className="container-custom container">
          <div className="row">
            <div className="col-12">
              <div className="header-top-inner">
                <img
                  src={HeaderLogo}
                  alt="alltech logo"
                  className="header-logo"
                />
                <div className="header-logout">
                  {(pathname === '/dashboard/home' ||
                    pathname === '/dashboard/settings') && (
                    <select
                      name="lang"
                      value={language}
                      className={'mySelect'}
                      style={{ marginRight: '10px' }}
                      onChange={changeLanguage}
                    >
                      <option value="en">English</option>
                      <option value="es">Español</option>
                      <option value="po">Português</option>
                      <option value="ch">中国人</option>
                    </select>
                  )}
                  <button
                    className="header-logout btn action-primary"
                    onClick={logoutHandler}
                  >
                    {t('log_out')}
                    <img src={LogoutIcon} alt="logout icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-nav">
        <nav className="container-custom container nav-menu">
          <div className="row">
            <div className="col-12">
              <ul className="nav-list">
                {data.map(({ to, value, notificationType }) => {
                  const hasNotif = notificationType
                    ? notificationsState.notifications.some(
                        ntf => ntf.Type.Name === notificationType,
                      )
                    : false;
                  const countOfNotifications = notificationsState.notifications.filter(
                    n => n.Type.Name === notificationType,
                  ).length;
                  return (
                    <li
                      className={`list-item ${pathname === to && 'active'}`}
                      key={to}
                    >
                      <Link to={to} className="item-link">
                        {t(value)}{' '}
                        {hasNotif && <span>{countOfNotifications}</span>}
                      </Link>
                    </li>
                  );
                })}
                <div className="notifications-wrapper">
                  {notificationsObjects.map(n => (
                    <Notification
                      message={n.message}
                      onClose={() => dispatch(closeNotification(n.type))}
                      to={n.to}
                    />
                  ))}
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export { Header };
