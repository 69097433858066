import { AssessmentActionTypes } from './actions';
import { IAssessmentSingleState, IGridPayload } from './types';
import { Reducer } from 'redux';
import { AssessmentDataCollectionModel } from '../../pages/Assessment/types';

const initialState: IAssessmentSingleState = {
  isLoading: false,
  error: '',
  data: [],
  saveModalOpened: false,
  // removeRowFunctionCounter: 0
};
export const assessmentSingleReducer: Reducer<IAssessmentSingleState, any> = (
  state: IAssessmentSingleState = initialState,
  action: any,
): IAssessmentSingleState => {
  switch (action.type) {
    case AssessmentActionTypes.ASSESSMENT_LOADING:
      return { ...state, isLoading: action.payload };
    case AssessmentActionTypes.ASSESSMENT_SET_ASSESSMENT:
      return { ...state, data: action.payload };
    case AssessmentActionTypes.ASSESSMENT_GET_ERROR:
      return { ...state, error: action.payload };
    case AssessmentActionTypes.ASSESSMENT_UPDATE_ASSESSMENT: {
      return {
        ...state,
        data: state.data.map(assessment => ({
          ...assessment,
          Pages: assessment.Pages.map(page => {
            return {
              ...page,
              Fields: page.Fields.map(field => {
                return {
                  ...field,
                  ...action.payload.find(newField => {
                    return newField.ID === field.ID;
                  }),
                };
              }),
            };
          }),
        })),
      };
    }
    case 'ADD_GRID_ROW': {
      return {
        ...state,
        data: addGridRowHelper(state.data, action.payload),
      };
    }
    case 'REMOVE_GRID_ROW': {
      return {
        ...state,
        data: removeGridHelper(state.data, action.payload),
      };
    }
  }
  return state;
};

const addGridRowHelper = (
  state: AssessmentDataCollectionModel[],
  payload: IGridPayload,
): AssessmentDataCollectionModel[] => {
  const newState = [...state];
  const indexOfUpdatedAssessment = newState.findIndex(
    ass => ass.ID === payload.assId,
  );

  if (indexOfUpdatedAssessment === -1) {
    return state;
  }

  const updatedAssessment = state.find(ass => ass.ID === payload.assId);
  const pageToExtend = updatedAssessment.Pages[payload.pageNum];
  const gridToExtend = pageToExtend.Fields.find(
    f => f.Template.Name === payload.gridName,
  );

  if (!gridToExtend) {
    return state;
  }

  const newArrayOfFields = gridToExtend.GridChildren.slice(0, 1);
  const finalFieldsArray = [];

  if (newArrayOfFields.length === 0) {
    return state;
  }

  let lastChildId = 0;

  if (gridToExtend.GridChildren.length > 0) {
    const lastElement = gridToExtend.GridChildren[gridToExtend.GridChildren.length - 1];
    lastChildId = lastElement[lastElement.length - 1].ID;
  }

  newArrayOfFields.forEach(fieldsArray => {
    const newFieldsArray = [];
    fieldsArray.forEach(field => {
      const newField = { ...field };
      ++lastChildId;
      newField.GridRow = gridToExtend.GridChildren.length;
      newField.Value = null;
      newField.ID = lastChildId;
      newFieldsArray.push(newField);
    });
    finalFieldsArray.push(newFieldsArray);
  });

  gridToExtend.GridChildren.push(...finalFieldsArray);

  const indexOfGrid = pageToExtend.Fields.findIndex(
    f => f.Template.Name === gridToExtend.Template.Name,
  );

  if (indexOfGrid !== -1) {
    pageToExtend.Fields.splice(indexOfGrid, 1, gridToExtend);
  }

  updatedAssessment.Pages[payload.pageNum] = pageToExtend;
  newState.splice(indexOfUpdatedAssessment, 1, updatedAssessment);

  return newState;
};

const removeGridHelper = (
  state: AssessmentDataCollectionModel[],
  payload: IGridPayload,
): AssessmentDataCollectionModel[] => {
  return state.map(assessment => {
    if (assessment.ID === payload.assId) {
      return {
        ...assessment,
        Pages: assessment.Pages.map((page, pageIndex) => {
          if (pageIndex === payload.pageNum) {
            // console.log(payload.removeCount -1);
            return {
              ...page,
              Fields: page.Fields.map(field => {
                if (field.Template.Name === payload.gridName) {
                  const newGridChildren = field.GridChildren.slice(0, payload.removeCount);
                  return {
                    ...field,
                    GridChildren: newGridChildren,
                  };
                }
                return field;
              }),
            };
          }
          return page;
        }),
      };
    }
    return assessment;
  });
};