import { ROUTES } from 'config/routes';
import { useFormikContext } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { postAssessment } from 'store/assessmentSingle/actions';

interface IProps {
  isOpen: boolean;
  setIsOpen: any;
  data: any;
  changedFieldsId: any;
  setChangedFieldsId: any;
  setUserHandleSaveData: any;
}

const SavePopup = ({
  isOpen,
  setIsOpen,
  changedFieldsId,
  setChangedFieldsId,
  setUserHandleSaveData,
  data,
}: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();
  const onExit = () => {
    setIsOpen(false);
    history.push(ROUTES.assessments);
  };
  const { errors, values } = useFormikContext();

  const onSave = async () => {
    const dataToPost = [];
    let objEmpty = [];
    data?.Pages?.forEach(p =>
      p.Fields.forEach(f => {
        if (f.Template.FieldType.Name === 'Grid') {
          if (f.Template.Validator.MaxRows > f.GridChildren.length) {
            let lastRow = f.GridChildren[f.GridChildren.length - 1];
            if (lastRow) {
              objEmpty = [];
              let lastCeil = lastRow[lastRow?.length - 1];
              let id = lastCeil?.ID + 1;
              f.GridChildren[f.GridChildren.length - 1]?.forEach(item => {
                let newObj = {
                  ...item,
                  ID: id++,
                  Value: null,
                  GridRow: item.GridRow + 1,
                };
                objEmpty.push(newObj);
              });
            }
          }
          f.GridChildren.forEach(fieldsArray => {
            fieldsArray.forEach(field => {
              field.Value = (values[f.Guid] &&
                values[f.Guid][field.GridRow] &&
                values[f.Guid][field.GridRow][field.Template.Name]) as
                | string
                | null;
            });
          });
          f.GridChildren.push(objEmpty);
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({ ...f });
          }
        } else {
          if (changedFieldsId.includes(f.Guid)) {
            dataToPost.push({
              ...f,
              Value: values[f.Guid] as string,
            });
          }
        }
      }),
    );
    await dispatch(postAssessment(dataToPost, false));
    setChangedFieldsId([]);
    setUserHandleSaveData(true);
    setIsOpen(false);
    history.push(ROUTES.assessments);
  };
  return (
    <Modal centered show={isOpen} className="modal-small save-changes-modal">
      <div className="modal-content">
        <Modal.Header className="modal-header" translate="">
          <h5 className="modal-title">{t('AP_cont?')}</h5>
        </Modal.Header>
        <Modal.Footer className="modal-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-secondary"
                  data-dismiss="modal"
                  onClick={onExit}
                >
                  Exit
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn action-primary"
                  onClick={onSave}
                >
                  {t('AP_save')}
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SavePopup;
